export const links = [
  {
    id: 1,
    text: "home",
    url: "/",
  },
  {
    id: 2,
    text: "about",
    url: "/about",
  },
  // {
  //   id: 3,
  //   text: "open source",
  //   url: "/opensource",
  // },
  {
    id: 3,
    text: "Achievements",
    url: "/achievements",
  },
  {
    id: 4,
    text: "projects",
    url: "/projects",
  },
  {
    id: 5,
    text: "contact",
    url: "/contact",
  },
];
